import { AuthGet, AuthPost } from './request.service';

export const getClosingLines = (pageNumber, sports, startDate, endDate) => {
  return AuthGet(
    `/closing-lines?pageNumber=${pageNumber}&sports=${sports}&startDate=${startDate}&endDate=${endDate}`
  );
};

export const addClosingLines = data => {
  return AuthPost(`/closing-lines`, data);
};
