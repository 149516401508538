import axios from 'axios';
import { store } from 'store';

const baseURL = process.env.REACT_APP_BASE_URL;

export const AuthGet = async url => {
  try {
    const state = store.getState();
    console.log('state', url, state.auth.accessToken.jwtToken);
    const headers = {
      Authorization: state.auth.accessToken.jwtToken
    };

    return await axios.get(`${baseURL}${url}`, { headers }).then(res => res);
  } catch (error) {
    return {
      status: 'failed'
    };
    throw error;
  }
};

export const AuthGetMultiple = async urls => {
  try {
    const state = store.getState();

    const headers = {
      Authorization: state.auth.accessToken.jwtToken
    };

    return axios
      .all(urls.map(url => axios.get(`${baseURL}${url}`, { headers })))
      .then(res => res);
  } catch (error) {
    return {
      status: 'failed'
    };
    throw error;
  }
};

export const AuthPost = async (url, body) => {
  try {
    const state = store.getState();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: state.auth.accessToken.jwtToken
    };
    return await axios
      .post(`${baseURL}${url}`, body, { headers })
      .then(res => res);
  } catch (error) {
    return {
      status: 'failed'
    };
    throw error;
  }
};

export const AuthPut = async (url, body) => {
  try {
    const state = store.getState();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: state.auth.accessToken.jwtToken
    };

    return await axios
      .put(`${baseURL}${url}`, body, { headers })
      .then(res => res);
  } catch (error) {
    return {
      status: 'failed'
    };
    throw error;
  }
};

export const AuthDelete = async url => {
  try {
    const state = store.getState();

    const headers = {
      Authorization: state.auth.accessToken.jwtToken
    };

    return await axios.delete(`${baseURL}${url}`, { headers }).then(res => res);
  } catch (error) {
    return {
      status: 'failed'
    };
    throw error;
  }
};

export const NoAuthGet = async url => {
  try {
    return await axios.get(`${baseURL}${url}`).then(res => res);
  } catch (error) {
    return {
      status: 'failed'
    };
    throw error;
  }
};

export const NoAuthGetMultiple = async urls => {
  try {
    return axios
      .all(urls.map(url => axios.get(`${baseURL}${url}`)))
      .then(res => res);
  } catch (error) {
    return {
      status: 'failed'
    };
    throw error;
  }
};

export const NoAuthPost = async (url, body) => {
  try {
    const headers = {
      'Content-Type': 'application/json'
    };
    return await axios
      .post(`${baseURL}${url}`, body, { headers })
      .then(res => res);
  } catch (error) {
    return {
      status: 'failed'
    };
    throw error;
  }
};

export const NoAuthPut = async (url, body) => {
  try {
    const headers = {
      'Content-Type': 'application/json'
    };
    return await axios
      .put(`${baseURL}${url}`, body, { headers })
      .then(res => res);
  } catch (error) {
    return {
      status: 'failed'
    };
    throw error;
  }
};

export const NoAuthDelete = async url => {
  try {
    return await axios.delete(`${baseURL}${url}`).then(res => res);
  } catch (error) {
    return {
      status: 'failed'
    };
    throw error;
  }
};
