import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateOdds,
  updateClickColumns,
  updateAmount,
  updateFavLeagues,
  updateLoaded,
  updateClicksEdiDup,
  updatePartner,
  updatePendingActionGroup
} from 'store/modules/preferences/actions';
import {
  updateShareType,
  updateTippers,
  updateLeague
} from 'store/modules/search/actions';
import { login } from 'store/modules/auth/actions';
import useRouter from 'utils/useRouter';
import { storageUserKey } from "utils/constants";
import * as PreferencesService from 'services/preferences.service';
import * as PartnerService from 'services/partner.service';
import * as BetService from 'services/bet.service';
import * as TipperService from 'services/tipper.service';
import * as B365Service from 'services/b365.service';

const InitInfo = ({ children }) => {

  const preferences = useSelector(state => state.preferences);
  const search = useSelector(state => state.search);

  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    async function fetchData() {
      const current_user = JSON.parse(localStorage.getItem(storageUserKey));
      if (current_user) {
        dispatch(login({
          user: current_user.user,
          accessToken: current_user.accessToken,
          group: current_user.group
        }));

        const userName = current_user.user;

        const partnerRes = await PartnerService.getPartners();
        if (partnerRes.status === 200) {
          const customResult = partnerRes.data.map((item) => (
            {
              ...item,
              label: item.code,
              value: item.code,
            }
          ));
          dispatch(updatePartner(customResult));
        }

        const shareTypeRes = await BetService.getShareType();
        const tippersRes = await TipperService.getTippers();
        const leagueRes = await B365Service.getLeagues();

        // update dropdown all
        let _updateShareTypes;
        let _updateTippers;
        let _updateLeague;
        let _upClicks;

        const preferencesRes = await PreferencesService.getUserPreference(userName);
        if (preferencesRes.status === 200) {
          if (preferencesRes.data.preferences) {

            // update the preferences redux
            if (preferencesRes.data.preferences.odds) {
              dispatch(updateOdds(preferencesRes.data.preferences.odds));
            }

            if (preferencesRes.data.preferences.amount) {
              dispatch(updateAmount(preferencesRes.data.preferences.amount));
            }

            if (preferencesRes.data.preferences.leagues) {
              dispatch(updateFavLeagues(preferencesRes.data.preferences.leagues));
            }

            if (preferencesRes.data.preferences.clicks) {
              // update clicks function
              const saveClicks = preferencesRes.data.preferences.clicks;
              const checkClosingHome = saveClicks.find(item=>item.name === 'closing_home');

              if (checkClosingHome) {
                dispatch(updateClickColumns(saveClicks));
                _upClicks = saveClicks;
              } else {
                const profitPosition = saveClicks.findIndex(item => item.name === 'profit');

                const newClicks = [
                    ...saveClicks.slice(0, profitPosition+1),
                    {
                      id: '20',
                      name: "closing_home",
                      checked: true,
                      selected:false,
                      chosen: false,
                    },
                    {
                      id: '21',
                      name: "closing_away",
                      checked: true,
                      selected:false,
                      chosen: false,
                    },
                    {
                      id: '22',
                      name: "closing_handicap",
                      checked: true,
                      selected:false,
                      chosen: false,
                    },
                    ...saveClicks.slice(profitPosition+1)
                ];
                
                dispatch(updateClickColumns(newClicks));
                _upClicks = newClicks;
              }
            }

            if (preferencesRes.data.preferences.clicksED) {
              dispatch(updateClicksEdiDup(preferencesRes.data.preferences.clicksED));
            }

            if (preferencesRes.data.preferences.pendingActionGroup) {
              dispatch(updatePendingActionGroup(preferencesRes.data.preferences.pendingActionGroup));
            }
            
            dispatch(updateLoaded(true));

            // update the search redux
            // : shareType
            if (shareTypeRes.status === 200) {
              if (preferencesRes.data.search.shareTypes) {
                let existShareTypes = [];
                let newShareTypes = [];
                shareTypeRes.data.forEach(item=>{
                  const existItem = preferencesRes.data.search.shareTypes.find(a => a.share_type === item.share_type);
                  
                  if (existItem) {
                    existShareTypes.push(item);
                  } else {
                    newShareTypes.push(item);
                  }
                });

                _updateShareTypes = [...existShareTypes, ...newShareTypes];
              } else {
                _updateShareTypes = shareTypeRes.data;
              }
            }
            dispatch(updateShareType(_updateShareTypes));

            // :tippers
            if (tippersRes.status === 200) {
              if (preferencesRes.data.search.tippers) {
                let existTippers = [];
                let newTippers = [];
                tippersRes.data.forEach(item=>{
                  const existItem = preferencesRes.data.search.tippers.find(a => a.code === item.code);
                  
                  if (existItem) {
                    existTippers.push(item);
                  } else {
                    newTippers.push(item);
                  }
                });

                _updateTippers = [...existTippers, ...newTippers];
              } else {
                _updateTippers = tippersRes.data;
              }
            }
            dispatch(updateTippers(_updateTippers));

            // :league
            if (leagueRes.status === 200) {
              const customLeague = getLeaguesOption(leagueRes, preferencesRes.data.preferences.leagues);
              if (preferencesRes.data.search.league) {
                const newLeague = customLeague.filter(item => {
                  const existItem = preferencesRes.data.search.league.find(a => a.name === item.name && a.info.leagueName === item.info.leagueName);

                  if (existItem) {
                    return false;
                  } else {
                    return true;
                  }
                });

                const updateSaveLeague = preferencesRes.data.search.league.map(item=>{
                  const newItem = customLeague.find(a => a.name === item.name && a.info.leagueName === item.info.leagueName);
                  if (newItem) {
                    return newItem;
                  } else {
                    return item;
                  }
                })

                // console.log('!!!customLeague', customLeague);
                // console.log('!!!search.league', preferencesRes.data.search.league);
                // console.log('!!!newLeauge', newLeague);
                _updateLeague = getUpdateLeagues(updateSaveLeague, newLeague);
              } else {
                _updateLeague = getUpdateLeagues([], customLeague)
              }
            }
            dispatch(updateLeague(_updateLeague));

            // save to the preferences server
            const updateData = {
              user: userName,
              preferences: {
                ...preferencesRes.data.preferences,
                clicks: _upClicks
              },
              search: {
                ...preferencesRes.data.search,
                shareTypes: _updateShareTypes,
                tippers: _updateTippers,
                league: _updateLeague
              }
            }

            PreferencesService.updateUserPreference(updateData);

          } else {
            // update the search redux
            // : shareType
            if (shareTypeRes.status === 200) {
              _updateShareTypes = shareTypeRes.data;
            }
            dispatch(updateShareType(_updateShareTypes));

            // : tippers
            if (tippersRes.status === 200) {
              _updateTippers = tippersRes.data;
            }
            dispatch(updateTippers(_updateTippers));

            // :league
            if (leagueRes.status === 200) {
              const customLeague = getLeaguesOption(leagueRes, []);
              const newLeague = customLeague;
              _updateLeague = getUpdateLeagues([], newLeague)
            }
            dispatch(updateLeague(_updateLeague));

            // save to the preferences server
            const updateData = {
              user: userName,
              preferences,
              search: {
                ...search,
                shareTypes: _updateShareTypes,
                tippers: _updateTippers,
                league: _updateLeague
              }
            }
            PreferencesService.updateUserPreference(updateData);
          }
        }
      } else {
        router.history.push('/auth/login');
      }
    }

    fetchData();
  }, [])

  const getLeaguesOption = (response, popularLeagues) => {
    let containLeagues = [];

    response.data.forEach(a => {
      if (a.leagues.length === 0) {
        if (a.name !== 'MMA/Boxing' && a.name !== 'Tennis') {
          containLeagues.push({
            name: a.name,
            info: {
              sportId: a.id,
              leagueId: null,
              leagueName: a.name
            }
          });
        }
      } else {
        a.leagues.forEach(b => {
          // add popular categories first in the array with unshift method then push others
          if (b.popular) {
            containLeagues.unshift({
              name: 'Popular',
              info: {
                sportId: a.id,
                leagueId: b.id,
                leagueName: b.name
              }
            });
          }

          if (
            a.name !== 'MLB' &&
            a.name !== 'NFL' &&
            a.name !== 'NBA' &&
            a.name !== 'NHL'
          ) {
            containLeagues.push({
              name: a.name,
              info: {
                sportId: a.id,
                leagueId: b.id,
                leagueName: b.name
              }
            });
          }
        });
      }
    });

    if (popularLeagues?.length > 0) {
      const removeDefaultsAddPref = containLeagues.filter(
        cl => cl.name !== 'Popular'
      );

      popularLeagues.forEach(element => {
        removeDefaultsAddPref.unshift({
          name: 'Popular',
          info: {
            sportId: element.sportId,
            leagueId: element.id,
            leagueName: element.name
          }
        });
      });

      return removeDefaultsAddPref;
    } else {
      return containLeagues;
    }
  }

  const getUpdateLeagues = (originalLeague, newLeague) => {
    const groupByName = (acc, item) => {
      const name = item.name;
      if (name in acc) {
        acc[name].push(item);
      } else {
        acc[name] = [item];
      }
      return acc;
    };

    let originalLeagueGroups = Object.values(originalLeague.reduce(groupByName, {}));

    newLeague.forEach(item => {
      const existGroupNameIndex = originalLeagueGroups.findIndex(a => a[0].name === item.name);
      if (existGroupNameIndex >= 0) {
        originalLeagueGroups[existGroupNameIndex].push(item);
      } else {
        originalLeagueGroups.push([item]);
      }
    })

    const updateLeague = [].concat(...originalLeagueGroups);

    return updateLeague;
  }

  return children;
}

export default InitInfo;