import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, Card, CardContent } from '@material-ui/core';
import { useErrorHandler } from 'react-error-boundary';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Bet365Service from 'services/b365.service';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: '10px'
  }
}));

export default function AdvancedSearch(props) {
  const classes = useStyles();
  const { state, onAdvancedSearch } = props;
  const handleError = useErrorHandler();

  const [formState, setFormState] = useState({
    values: {
      pageNumber: 1,
      sports: [],
      startDate: moment()
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD')
    }
  });

  const [sportOptions, setSportOptions] = useState([]);

  useEffect(() => {
    Bet365Service.getLeagues().then(response => {
      if (response.status === 200) {
        try {
          const sports = response.data.map(item => item.name);

          setSportOptions(sports);
        } catch (err) {
          handleError('Bet365Service response error');
        }
      } else {
        handleError('Bet365Service api error');
      }
    });
  }, []);

  useEffect(() => {
    setFormState({
      values: {
        pageNumber: state.values.pageNumber,
        sports: state.values.sports,
        startDate: state.values.startDate,
        endDate: state.values.endDate
      }
    });
  }, [state]);

  const selectValueChange = (name, value) => {
    if (name === 'startDate') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          startDate: value,
          endDate: value
        }
      }));
    }

    if (name === 'endDate') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          endDate: value
        }
      }));
    }

    if (name === 'pageNumber') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          pageNumber: value
        }
      }));
    }
  };

  const multiSelectChange = (name, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value
      }
    }));
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={5}>
          <Grid item xs={4} lg={4}>
            <Autocomplete
              multiple
              size="small"
              value={sportOptions.filter(item =>
                formState.values.sports.find(a => a === item)
              )}
              onChange={(event, newValue) => {
                multiSelectChange('sports', newValue);
              }}
              options={sportOptions}
              getOptionLabel={option => option}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Sports"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <TextField
              label="Start Date"
              type="date"
              name="startDate"
              variant="outlined"
              size="small"
              value={formState.values.startDate}
              fullWidth
              onChange={event =>
                selectValueChange('startDate', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <TextField
              label="End Date"
              type="date"
              name="endDate"
              variant="outlined"
              size="small"
              value={formState.values.endDate}
              fullWidth
              onChange={event =>
                selectValueChange('endDate', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} lg={12} className="d-flex justify-content-end">
            <Button
              size="small"
              color="primary"
              className="justify-content-end"
              onClick={() => onAdvancedSearch(formState)}>
              Search
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
