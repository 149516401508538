import React, { forwardRef, useState, useEffect } from 'react';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  FileCopyOutlined
} from '@material-ui/icons';
import { IconButton, Tooltip, Checkbox, Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'assets/mycustom.scss';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const ViewData = props => {
  const { data, onUpdateClosingLinesOnTable } = props;

  const [realData, setRealData] = useState([]);

  useEffect(() => {
    setRealData(data);
  }, [data]);

  return (
    <div className="generate-table">
      <MaterialTable
        title=""
        icons={tableIcons}
        columns={[
          {
            title: 'ID',
            field: 'id',
            filtering: false,
            editable: 'never',
            width: 50,
            cellStyle: {
              paddingRight: '5px',
              paddingLeft: '5px',
              paddingTop: '0px',
              paddingBottom: '0px',
              fontSize: '13px'
            }
          },
          {
            title: 'EventId',
            field: 'event_id',
            width: 80,
            cellStyle: {
              paddingTop: '5px',
              paddingBottom: '5px',
              fontSize: '13px'
            },
            editable: 'never',
            hidden: true
          },
          {
            title: 'Date',
            field: 'date',
            width: 80,
            cellStyle: {
              paddingTop: '5px',
              paddingBottom: '5px',
              fontSize: '13px'
            },
            editable: 'never'
          },
          {
            title: 'Event',
            field: 'event_name',
            width: 80,
            cellStyle: {
              paddingTop: '5px',
              paddingBottom: '5px',
              fontSize: '13px'
            },
            editable: 'never'
          },
          {
            title: 'Bet Description',
            field: 'short_description',
            width: 80,
            cellStyle: {
              paddingTop: '5px',
              paddingBottom: '5px',
              fontSize: '13px'
            },
            editable: 'never'
          },
          {
            title: 'Closing For',
            field: 'closing_home',
            width: 80,
            cellStyle: {
              paddingTop: '0px',
              paddingBottom: '0px',
              fontSize: '13px'
            },
            render: rowData => {
              return (
                <div className="d-flex">
                  <span>{rowData.closing_home}</span>
                </div>
              );
            }
          },
          {
            title: 'Closing Against',
            field: 'closing_away',
            width: 80,
            cellStyle: {
              paddingTop: '0px',
              paddingBottom: '0px',
              fontSize: '13px'
            },
            render: rowData => {
              return (
                <div className="d-flex">
                  <span>{rowData.closing_away}</span>
                </div>
              );
            }
          }
        ]}
        data={realData}
        options={{
          columnsButton: true,
          actionsColumnIndex: -1,
          headerStyle: {
            background: '#f4f5fd',
            color: '#3b3e66',
            borderTop: '2px solid #dcdef1',
            borderBottom: '2px solid #dcdef1'
          },
          exportButton: true,
          pageSize: realData.length === 0 ? 1 : 200,
          padding: 'dense',
          paging: false
        }}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
              // console.log('newValue: ' + newValue);
              // console.log('rowData', rowData)
              let newData;

              if (String(columnDef.field) === 'closing_home') {
                newData = {
                  ...rowData,
                  closing_home: newValue
                };
                onUpdateClosingLinesOnTable(newData);
              }

              if (String(columnDef.field) === 'closing_away') {
                newData = {
                  ...rowData,
                  closing_away: newValue
                };
                onUpdateClosingLinesOnTable(newData);
              }

              setTimeout(resolve, 2000);
            });
          }
        }}
        components={{
          Action: props => <></>
        }}
      />
    </div>
  );
};

export default ViewData;
